import React from 'react';
import Switch from "react-switch";

export default class Calculadora extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            precioMensual: 400,
            suscriptores: '',
            checked: false
        };
        this.cambioSuscriptores = this.cambioSuscriptores.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    cambioSuscriptores(event) {
        this.setState({ suscriptores: event.target.value });
    }
    handleChange(checked) {
        if(checked)
        this.setState({ precioMensual: 450 });
        else
        this.setState({ precioMensual: 350 });        

        this.setState({ checked });
    }

    renderPrecio()
    {
        if(this.state.precioMensual * this.state.suscriptores < 50000)
        return (<>${(50000).toLocaleString()} <span style={{fontSize: "20px"}}> COP /mes</span></>);
        else if(this.state.suscriptores* this.state.precioMensual >= 50000 && this.state.suscriptores < 5000)
        return (<>${(this.state.precioMensual * this.state.suscriptores).toLocaleString()} <span style={{fontSize: "20px"}}> COP /mes</span></>);
        else
        return (  <span style={{fontSize: "20px"}}> Contáctenos para establecer un precio y solución ajustada a su medida</span>);
    }


    render() {
        return <section className="pricing py-5">
            <div className="container">
                <div className="section-header">
                    <h2>Precio</h2>
                    <p></p>
                </div>
                <div className="row justify-content-center">

                    <div className="col-lg-6">
                        <div className="card mb-5 mb-lg-0">
                            <h5 className="card-header text-center">
                                Pague según el tamaño de su acueducto
                            </h5>
                            <div className="card-body">
                                <div className="form-group">
                                <small className="form-text text-muted">
                                        El número de usuarios que atiende su acueducto:
                                 </small>
                                    <input value={this.state.suscriptores} onChange={this.cambioSuscriptores} type="number" className="form-control text-center" placeholder="# de suscriptores" />
                                  
                                </div>
                                <h6 className="card-price text-center">X</h6>

                                <h2 className="card-price text-center">
                                    ${this.state.precioMensual}  <span style={{fontSize: "20px"}}>COP /mes por suscriptor</span>
                                   
                                </h2>
                               
                                <div className="form-group">
                                    <h6 className="card-price text-center">=</h6>
                                    <h1 className="card-price text-center">
                                        {this.renderPrecio()}
                                    </h1>
                                </div>
                                {/* <div className="row justify-content-center">
                                Incluir sistema contable y financiero    
                                    <label style={{marginLeft: "10px"}}>
                                        <Switch
                                            onChange={this.handleChange}
                                            checked={this.state.checked}
                                            id="normal-switch"
                                        />
                                    </label>
                                </div> */}
                                {/* <div className="text-center"><button className="btn btn-danger">Contacto</button></div> */}

                                <h6 className="py-2 text-center text-muted">
                                    Para servicios adicionales como presupuesto, contabilidad, tesorería y otros contáctenos
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    }
}
