import React from "react"
import PropTypes from "prop-types"

import '../scss/index.scss'



import carousel1 from "../img/intro-carousel/1.jpg"

import mockup from "../img/about-img.png"

import facturacion from "../img/servicios/facturacion.png"
import suscriptores from "../img/servicios/suscriptores.png"
import sui from "../img/servicios/sui.png"
import cartera from "../img/servicios/cartera.png"
import pqrs from "../img/servicios/pqrs.png"
import contabilidad from "../img/servicios/contabilidad.png"

import velez from "../img/clients/emprevel.jpg"
import clientes from "../docs/Experiencia y Casos de exito SOLIN vPW.pdf"
import ibal from "../img/clients/ibal.jpg"
import progresar from "../img/clients/progresar.jpg"
import puertoSalgar from "../img/clients/puerto-salgar.png"
import viota from "../img/clients/viota.png"
import zipacon from "../img/clients/zipacon.png"



import logn from "../img/logn.png"
import Helmet from 'react-helmet'

import Contacto from "./contacto"
import Calculadora from "./calculadora"

import { WhatsappIcon } from 'react-share';
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'
// import 'jquery-ui/ui/effects/effect-slide'




export default class Layout extends React.Component {



  componentDidMount() {
    if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
      const WOW = require("wowjs");
      const animation = require("animate.css");
      const sticky = require("jquery-sticky");
      // require('jquery.easing')($);

      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('.back-to-top').fadeIn('slow');
        } else {
          $('.back-to-top').fadeOut('slow');
        }
      });
      $('.back-to-top').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
        return false;
      });

      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('.whats-app').fadeIn('slow');
        } else {
          $('.whats-app').fadeOut('slow');
        }
      });

      // Stick the header at top on scroll
      $("#header").sticky({ topSpacing: 0, zIndex: '50' });

      // Intro background carousel
      // $("#intro-carousel").owlCarousel({
      //   autoplay: true,
      //   dots: false,
      //   loop: true,
      //   animateOut: 'fadeOut',
      //   items: 1
      // });

      // Initiate the wowjs animation library
      new WOW.WOW().init();

      // Initiate superfish on nav menu
      // $('.nav-menu').superfish({
      //   animation: {
      //     opacity: 'show'
      //   },
      //   speed: 400
      // });

      // Mobile Navigation
      if ($('#nav-menu-container').length) {
        var $mobile_nav = $('#nav-menu-container').clone().prop({
          id: 'mobile-nav'
        });
        $mobile_nav.find('> ul').attr({
          'class': '',
          'id': ''
        });
        $('body').append($mobile_nav);
        $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars"></i></button>');
        $('body').append('<div id="mobile-body-overly"></div>');
        $('#mobile-nav').find('.menu-has-children').prepend('<i class="fa fa-chevron-down"></i>');

        $(document).on('click', '.menu-has-children i', function (e) {
          $(this).next().toggleClass('menu-item-active');
          $(this).nextAll('ul').eq(0).slideToggle();
          $(this).toggleClass("fa-chevron-up fa-chevron-down");
        });

        $(document).on('click', '#mobile-nav-toggle', function (e) {
          $('body').toggleClass('mobile-nav-active');
          $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          $('#mobile-body-overly').toggle();
        });

        $(document).click(function (e) {
          var container = $("#mobile-nav, #mobile-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
              $('#mobile-body-overly').fadeOut();
            }
          }
        });
      } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
        $("#mobile-nav, #mobile-nav-toggle").hide();
      }

      // Smooth scroll for the menu and links with .scrollto classes
      $('.nav-menu a, #mobile-nav a, .scrollto').on('click', function () {
        if (window.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && window.location.hostname == this.hostname) {
          var target = $(this.hash);
          if (target.length) {
            var top_space = 0;

            if ($('#header').length) {
              top_space = $('#header').outerHeight();

              if (!$('#header').hasClass('header-fixed')) {
                top_space = top_space - 20;
              }
            }

            $('html, body').animate({
              scrollTop: target.offset().top - top_space
            }, 1500, 'easeInOutExpo');

            if ($(this).parents('.nav-menu').length) {
              $('.nav-menu .menu-active').removeClass('menu-active');
              $(this).closest('li').addClass('menu-active');
            }

            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
              $('#mobile-body-overly').fadeOut();
            }
            return false;
          }
        }
      });


      // Clients carousel (uses the Owl Carousel library)
      // $(".clients-carousel").owlCarousel({
      //   autoplay: true,
      //   dots: true,
      //   loop: true,
      //   responsive: { 0: { items: 2 }, 768: { items: 4 }, 900: { items: 6 }
      //   }
      // });
    }

  }
  openInNewTab() {
    var win = window.open("https://api.whatsapp.com/send?phone=573176455127", '_blank');
    win.focus();
  }



  render() {
    return (
      <div>
        <Helmet
          title="SolinCloud - Software de facturación para acueductos de cualquier tamaño."
          meta={[
            { name: 'description', content: 'SolinCloud es una plataforma que ofrece software como servicio  para empresas de que requiren gestionar sus procesos de facturación, matrículas, PQRs, entre otros.' },
            { name: 'keywords', content: 'software, acueductos, acueducto, colombia, facturación, acueducto, solin, solincloud, solin cloud' },
            // <script src={main} />
          ]}
        />
        <section id="topbar" className="d-none d-lg-block">
          <div className="container clearfix">
            <div className="contact-info float-left">
              <i className="fa fa-envelope-o"></i> <a href="mailto:solin@solin.com.co">solin@solin.com.co</a>
              <i className="fa fa-phone"></i> +571 621 42 40
        </div>
            {/* <div className="social-links float-right">
              <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
              <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
              <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
              <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
            </div> */}
          </div>
        </section>

        <header id="header">
          <div className="container">

            <div id="logo" className="pull-left">
              <a href="#topbar"><img src={logn} alt="" title="" style={{ height: "45px" }}
                className="img-responsive left-block " /></a>
            </div>

            <nav id="nav-menu-container">
              <ul className="nav-menu">
                <li className="menu-active"><a href="#topbar">Inicio</a></li>
                <li><a href="#about">¿Qué es SOLINCloud?</a></li>
                <li><a href="#services">Servicios</a></li>
                <li><a href="#clients">Clientes</a></li>
                <li><a href="#nosotros">¿Quienes somos?</a></li>
                <li><a href="#contact">Contacto</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <section id="intro">

          <div className="intro-content">
            <h2>Gestione su <span>acueducto</span><br />¡simplifique sus procesos!</h2>
            <div>
              <a href="#services" className="btn-get-started scrollto">Ventajas</a>
              <a href="#contact" className="btn-projects scrollto">Empezar ahora</a>
            </div>
          </div>

          <div id="intro-carousel" className="owl-carousel">
            <div className="item" style={{ backgroundImage: carousel1 }}></div>
          </div>

        </section>

        <main id="main">

          <section id="about" className="wow fadeInUp">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 about-img">
                  <img src={mockup} alt="" />
                </div>

                <div className="col-lg-6 content">
                  <h2>¿Qué es SOLINCloud?</h2>
                  <h3>SOLINCloud es un software en la nube que permite gestionar integralmente los procesos de facturación,
                recaudo y cartera.</h3>

                  <ul>
                    <li><i className="ion-android-checkmark-circle"></i> Sistema para empresas de acueducto pequeño o gran tamaño.
                </li>
                    <li><i className="ion-android-checkmark-circle"></i> Los costos de la solución se adaptan a su negocio.</li>
                    <li><i className="ion-android-checkmark-circle"></i> Moderna gestión de datos en la nube.</li>
                  </ul>

                </div>
              </div>

            </div>
          </section>

          <section id="services">
            <div className="container">
              <div className="section-header">
                <h2>Servicios</h2>
                <p>No importa el tamaño de su empresa, SolinCloud se adapta a las necesidades y presupuesto de su acueducto.
            </p>
              </div>

              <div className="row">

                <div className="col-lg-6">
                  <div className="box wow fadeInLeft">
                    <div className="icon">
                      <img src={facturacion} alt="" title="" style={{ height: "90px" }}
                        className="img-responsive left-block " />
                    </div>
                    <h4 className="title"><a href="">Facturación</a></h4>
                    <p className="description">Gestiona consumos, lecturas, liquidación e impresión de facturas.</p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="box wow fadeInRight">
                    <div className="icon">
                      <img src={suscriptores} alt="" title="" style={{ height: "90px" }}
                        className="img-responsive left-block " />
                    </div>
                    <h4 className="title"><a href="">Suscriptores</a></h4>
                    <p className="description">Mantenga actualizado y centralizado el catastro de suscriptores.</p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="box wow fadeInLeft" data-wow-delay="0.2s">
                    <div className="icon">
                      <img src={sui} alt="" title="" style={{ height: "90px" }}
                        className="img-responsive left-block " />
                    </div>
                    <h4 className="title"><a href="">Informes al SUI</a></h4>
                    <p className="description">Genere fácilmente todos los reportes que requiere para la Superintendencia de
                  servicios públicos.</p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="box wow fadeInRight" data-wow-delay="0.2s">
                    <div className="icon">
                      <img src={cartera} alt="" title="" style={{ height: "90px" }}
                        className="img-responsive left-block " />
                    </div>
                    <h4 className="title"><a href="">Recaudos y cartera</a></h4>
                    <p className="description">Registre fácilmente el pago de sus clientes. Controle los cobros de la cartera
                  pendiente por recaudar.</p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="box wow fadeInLeft" data-wow-delay="0.2s">
                    <div className="icon">
                      <img src={pqrs} alt="" title="" style={{ height: "90px" }}
                        className="img-responsive left-block " />
                    </div>
                    <h4 className="title"><a href="">Gestión al cliente - PQRs</a></h4>
                    <p className="description">Mantenga el control y seguimiento de las PQRs interpuestas y realice eficientemente su gestión.</p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="box wow fadeInRight" data-wow-delay="0.2s">
                    <div className="icon">
                      <img src={contabilidad} alt="" title="" style={{ height: "90px" }}
                        className="img-responsive left-block " />
                    </div>
                    <h4 className="title"><a href="">Contabilidad</a></h4>
                    <p className="description"> Integre, de manera automática,  la gestión comercial y de cartera a la contabilidad.</p>
                  </div>
                </div>

              </div>

            </div>
          </section>

          <section id="call-to-action" className="wow fadeInUp">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 text-center text-lg-left">
                  <h3 className="cta-title">Conozca nuestro producto</h3>
                  <p className="cta-text"> Reciba una demostración completa de nuestra solución sin ningún compromiso.</p>
                </div>
                <div className="col-lg-3 cta-btn-container text-center">
                  <a className="cta-btn align-middle" href="#contact">Solicitar</a>
                </div>
              </div>

            </div>
          </section>

          <section id="clients" className="wow fadeInUp">
            <div className="container">

              <div className="section-header">
                <h2>Clientes</h2>
                <p>Estas son algunas empresas que han decidido confiar en nosotros para gestionar sus procesos.</p>
              </div>

                   
             
              <div className="row">

              <div className="owl-carousel clients-carousel col-9">
                <img src={velez} alt="Acueducto de Vélez - Santander" style={{ height: "100px", width: "auto" }} />
                <img src={ibal} alt="Acueducto de Ibagué - Tolima" style={{ height: "100px", width: "auto" }} />
                <img src={progresar} alt="Progresar ESP" style={{ height: "100px", width: "auto" }} />
                <img src={puertoSalgar} alt="Acueducto de Puerto Salgar - Cundinamarca" style={{ height: "100px", width: "auto" }} />
                <img src={viota} alt="Acueducto de Viotá - Cundinamarca" style={{ height: "100px", width: "auto" }} />
                <img src={zipacon} alt="Acueducto de Zipacón - Cundinamarca"  style={{ height: "100px", width: "auto" }} />      
         
              </div>        
              <div className="col-2">
              <a class="btn btn-primary"  href={clientes} download>Ver todos</a>
              </div> 
            </div>  
            
            
            
            </div>
          </section>


          <section id="nosotros" className="wow fadeInUp">
            <div className="container">
              <div className="row">


                <div className="col-lg-6 content">
                  <h2>¿Quienes somos?</h2>
                  <h3>Sistemas Integrales LTDA es una empresa con más de 25 años de experiencia en el sector.</h3>

                  <ul>
                    <li><i className="ion-android-checkmark-circle"></i> Hemos ejecutado más de 300 proyectos de tecnología
                      exitosamente.
                </li>
                    <li><i className="ion-android-checkmark-circle"></i> Somos fábrica de software certificada en CMMI Nivel 5.</li>
                    <li><i className="ion-android-checkmark-circle"></i> Ofrecemos servicios a más de 30 acueductos en todo el país.</li>
                  </ul>

                </div>
                <div className="col-lg-6 nosotros-img">
                  <img src={logn} alt="" />
                  <br />
                  <br />
                  <h4 style={{ textAlign: "center" }}>Sistemas Integrales Ltda</h4>
                </div>
              </div>

            </div>
          </section>
          <Calculadora />

          <Contacto />

        </main>

        <footer id="footer">
          <div className="container">
            <div className="copyright">
              © {new Date().getFullYear()}<strong> Sistemas Integrales Ltda </strong>
            </div>

          </div>
        </footer>


        <a data-tip="Contáctenos por WhatsApp!" className="whats-app" onClick={this.openInNewTab}>
          <WhatsappIcon
            
            size={50}
            round={true}
          />
        </a>
        <a href="#" className="back-to-top"><i className="fa fa-chevron-up"></i></a>
        <ReactTooltip />

      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `)
// <Header siteTitle={data.site.siteMetadata.title} />
//     <div
//       style={{
//         margin: `0 auto`,
//         maxWidth: 960,
//         padding: `0px 1.0875rem 1.45rem`,
//         paddingTop: 0,
//       }}
//     >
//       <main>{children}</main>
//       <footer>
//         © {new Date().getFullYear()}, Built with
//         {` `}
//         <a href="https://www.gatsbyjs.org">Gatsby</a>
//       </footer>
//     </div>

