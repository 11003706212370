import React from 'react'
import * as emailjs from 'emailjs-com';

export default class Contacto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "empresa": '',
            "mensaje": '',
            "email": '',
            "nombre": '',
            "telefono": ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        emailjs.init('user_zSIfPsFMhFF4RigYMWCSh');
    }

    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    onSubmit = event => {
        event.preventDefault();
        var { empresa, mensaje, email, nombre, telefono } = this.state;
        if (!empresa || !email || !nombre || !telefono) {
            alert("Por favor complete los campos obligatorios para enviar el mensaje.");
            return;
        }

        this.setState({
            empresa: '',
            mensaje: '',
            email: '',
            nombre: '',
            telefono: '',
        });
        emailjs
            .send('gmail', 'contactosolincloud', {
                empresa: empresa,
                mensaje: mensaje,
                ejs_dashboard__test_template: true,
                correo: email,
                nombre: nombre,
                telefono: telefono,
            })
            .then(() => {
                alert('El correo ha sido enviado exitosamente.');
                event.preventDefault();
            })
            .error(error => {
                alert('Error enviando el mensaje, intente de nuevo por favor.');
                event.preventDefault();
            });


    };

    render() {
        return <section id="contact" className="wow fadeInUp">
            <div className="container">
                <div className="section-header">
                    <h2>Contactenos</h2>
                    <p>Déjenos sus datos y lo contactaremos a la mayor brevedad.</p>
                </div>

                <div className="row contact-info">

                    <div className="col-md-3">
                        <div className="contact-address">
                            <i className="ion-ios-location-outline"></i>
                            <h3>Dirección</h3>
                            <address>Cra 21A # 82-16, Bogotá D.C., Colombia</address>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="contact-address">
                            <i className="ion-ios-email-outline"></i>
                            <h3>Email</h3>
                            <p><a href="mailto:solin@solin.com.co">solin@solin.com.co</a></p>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="contact-address">
                            <i className="ion-ios-telephone-outline"></i>
                            <h3>Teléfono</h3>
                            <p><a href="tel:+5716214240">+571 621 4240</a></p>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="contact-address">
                            <i className="ion-ios-telephone-outline"></i>
                            <h3>Teléfono móvil</h3>
                            <p><a href="tel:+5716214240">+57 317 645 5127</a></p>
                        </div>
                    </div>



                </div>
            </div>


            <div className="container">
                <div className="form">
                    <form onSubmit={this.onSubmit} className="contactForm">
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <input type="text" className="form-control" placeholder="Nombre (obligatorio)"
                                    name="nombre"
                                    value={this.state.nombre}
                                    onChange={this.handleInputChange} />
                                <div className="validation"></div>
                            </div>
                            <div className="form-group col-md-4">
                                <input type="email" className="form-control" placeholder="Correo (obligatorio)"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange} />
                            </div>
                            <div className="form-group col-md-4">
                                <input type="number" className="form-control" placeholder="Teléfono (obligatorio)"
                                    name="telefono"
                                    value={this.state.telefono}
                                    onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Empresa (obligatorio)"
                                name="empresa"
                                value={this.state.empresa}
                                onChange={this.handleInputChange} />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" rows="5" placeholder="Mensaje"
                                name="mensaje"
                                value={this.state.mensaje}
                                onChange={this.handleInputChange}  ></textarea>
                        </div>
                        <div className="text-center"><button type="submit" className="btn btn-danger">Enviar</button></div>
                    </form>
                </div>

            </div>
        </section>;
    }
}